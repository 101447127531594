<template>

	<div class="w-100 h-100 position-relative overflow-hidden">

		<wp_login v-if="site" v-model="site" :workspace="workspace" :redirect="preview" v-on:update:submitted="update_submitted" />

		<div v-if="site" class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<design_nav 
				:site="site" 
				v-model="preview_size" 
				:preview="staging" 
				:inspect="inspect" 
				v-on:update:inspectValue="(e) => inspect = e" 
				:zoom="zoom" 
				v-on:update:zoomValue="(e) => zoom = e" 
				:show_share="true"
				/>

				<v-tour name="prototype-tour" :steps="steps"></v-tour>

				<div class="w-100 h-100 position-relative overflow-hidden d-flex">
					
					<preview_settings 
						v-if="site"
						:is_show="true"
						v-model="site"
						:is_design="true"
						:workspace="workspace"
						:mode="'prototype'"
						v-on:update:modelValue="update_site"
						v-on:update:showTour="show_tour"
						v-on:update:previewSize="(e) => { preview_size = e }"
						:show_tour="true"
					/>

					<!-- <div class="overflow-hidden flex-grow-1" style="padding-bottom: 50px;">
						<div class="w-100 h-100 border-bottom iframe-container overflow-auto position-relative">
							<div v-if="iframe_loading" class="position-absolute top-50 start-50 translate-middle">
								<span class="spinner-border" role="status">
									<span class="visually-hidden">Loading...</span>
								</span>
							</div>
							<div class="overflow-hidden" :style="'height: ' + iframe_container_height + ';'">
								<iframe @load="load_iframe" name="preview" :src="preview" class="mx-auto d-block iframe" :class="[iframe_loading ? 'opacity-0' : '']" :style="['width:' + iframe_width, transform, 'height:' + iframe_height]"></iframe>
							</div>
						</div>
					</div>	 -->

					<div class="overflow-hidden flex-grow-1 position-relative" style="padding-bottom: 50px;">

						<div class="position-absolute w-100 bottom-0 start-0 d-flex align-items-center justify-content-center bg-light p-2" style="z-index: 10;">
							
							<!-- <button v-on:click="inspect = !inspect" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2 " :class="inspect ? 'border-primary' : 'border-light'">
								<span class="material-icons-outlined me-2">highlight_alt</span>
								Inspect
							</button> -->

							<div class="btn-group v-step-device">
								<button v-on:click="preview_size = 'mobile'" class="btn btn-sm d-flex align-items-center" :class="preview_size == 'mobile' ? 'btn-primary' : 'btn-outline-primary'">
									<span class="material-icons-outlined">smartphone</span>
								</button>
								<button v-on:click="preview_size = 'tablet'" class="btn btn-sm d-flex align-items-center" :class="preview_size == 'tablet' ? 'btn-primary' : 'btn-outline-primary'">
									<span class="material-icons-outlined">tablet_mac</span>
								</button>
								<button v-on:click="preview_size = 'desktop'" class="btn btn-sm d-flex align-items-center" :class="preview_size == 'desktop' ? 'btn-primary' : 'btn-outline-primary'">
									<span class="material-icons-outlined">desktop_windows</span>
								</button>
							</div>

							<div class="d-flex align-items-center ms-3">
								<div class="btn-group">
									<button v-on:click="update_zoom( -10 )" class="btn btn-sm btn-outline-primary d-flex align-items-center" type="button">
										<span class="material-icons-outlined">zoom_out</span>
									</button>
									<button v-on:click="update_zoom( 10 )" class="btn btn-sm btn-outline-primary d-flex align-items-center" type="button">
										<span class="material-icons-outlined">zoom_in</span>
									</button>
								</div>
								<span class="small ms-2 v-step-zoom" style="width: 50px;">{{ zoom }}%</span>
							</div>
						</div>

						<div class="w-100 h-100 border-bottom iframe-container overflow-auto position-relative">
							<div v-if="!iframe_loaded || !wp_submitted" class="position-absolute top-50 start-50 translate-middle">
								<span class="spinner-border" role="status">
									<span class="visually-hidden">Loading...</span>
								</span>
							</div>

							<div class="overflow-hidden" :style="'height: ' + iframe_container_height + ';'">
								<iframe 
								v-on:load="load_iframe"
								:src="preview"
								name="iframe-preview" 
								class="mx-auto d-block iframe" 
								:class="[(!iframe_loaded || !wp_submitted ? 'opacity-0' : '')]" 
								:style="['width:' + iframe_width, transform, 'height:' + iframe_height]"
								:scrolling="iframe_height && iframe_height != '100%' ? 'no' : ''"
								></iframe>
							</div>
						</div>
					</div>				

				</div>

			</div>

		</div>

		<div class="modal fade" id="success" tabindex="-1" aria-labelledby="successLabel" aria-hidden="true">

			<confetti />

			<div class="modal-dialog modal-dialog-centered" style="z-index: 10">
				<div class="modal-content overflow-hidden">
					<div class="modal-body p-0">
						<div class="card mb-0 bg-primary rounded-0 text-white border-0 position-relative">

							<div class="card-body pe-4 p-0 d-flex align-items-center justify-content-start">

								<div class="me-2">
									<div class="mx-auto overflow-hidden" style="width: 250px; height: 250px;">
										<img src="@/assets/img/leo.png" class="img-fluid">
									</div>
								</div>

								<div>
									<h2 class="h3">All done!</h2>
									<p class="mb-0">Customise the designs until your happy with the look and feel of the site before fine tuning individual pages.</p>
									
								</div>

							</div>
						</div>
					</div>
					<div class="modal-footer justify-content-between bg-primary" style="border-color: rgba( 255, 255, 255, 0.2 )">
						<button type="button" class="btn btn-link text-white" v-on:click="hide_success">Skip tour</button>
						<button type="button" class="btn btn-secondary" v-on:click="show_tour">Take tour</button>
					</div>
				</div>
			</div>
		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import design_nav from '@/components/design_nav'
import preview_settings from '@/components/preview_settings'
import confetti from '@/components/confetti'
import wp_login from '@/components/wp_login'

export default {
	name: 'site.design.prototype',

	props: {
		modelValue: [Object],
		workspace: [Object],
	},

	emits: [
		'update:modelValue',
	],

	components: {
		design_nav,
		preview_settings,
		confetti,
		wp_login
	},

	data() {
		return {
			loading: false,
			wp_submitted: false,
			iframe_loaded: false,
			error: {},
			site: null,
			version: 0,
			preview_size: 'desktop',
			inspect: false,
			zoom: 100,
			iframe_loading: true,
			iframe_height: '100%',
			scroll_height: null,
			wp_page: null,
			steps: [
				{
					target: '.v-step-zoom',
					header: {
						title: 'Zoom',
					},
					content: `Zoom in and out of your preview to see the full flow of your designs.`,
					params: {
						placement: 'right'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-device',
					header: {
						title: 'Preview',
					},
					content: `Toggle your view to preview your site on desktop, tablet and mobile.`,
					params: {
						placement: 'left'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-0',
					header: {
						title: 'Pattern combos',
					},
					content: `Consistently change the layout of your entire site with one click.`,
					params: {
						placement: 'right'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-1',
					header: {
						title: 'Colour modes',
					},
					content: `Automate the colour selection for each of the sections on your site.`,
					params: {
						placement: 'right'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-2',
					header: {
						title: 'Font sizes',
					},
					content: `Change the size of headings and text consistently throughout your site.`,
					params: {
						placement: 'right'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-3',
					header: {
						title: 'Style',
					},
					content: `Change button, accordion and card styles consistently across your site.`,
					params: {
						placement: 'right'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-4',
					header: {
						title: 'Alignment',
					},
					content: `Alternate alignment of content and media within blocks automatically.`,
					params: {
						placement: 'right'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-5',
					header: {
						title: 'Dividers',
					},
					content: `Add stylised dividers to separate each block across your site.`,
					params: {
						placement: 'right'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-6',
					header: {
						title: 'Transitions',
					},
					content: `Apply transitions to text and media as users scroll down each page.`,
					params: {
						placement: 'right'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-7',
					header: {
						title: 'Hover effects',
					},
					content: `Change hover effects for buttons and cards consistently across your site.`,
					params: {
						placement: 'right'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-8',
					header: {
						title: 'Parallax',
					},
					content: `Add parallax to each page and customise with your clients brand.`,
					params: {
						placement: 'right'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
			],
		}
	},

	watch: {
		'preview': function()
		{
			this.iframe_loaded = false
		},
		'inspect': function()
		{
			var $ = window.$;

			$( '.iframe' )[0].contentWindow.postMessage( { 
				origin: 'areoi-platform',
				inspect: this.inspect,
				zoom: this.zoom
			}, this.preview );
		},
		'zoom': function()
		{
			var $ = window.$;

			$( '.iframe' )[0].contentWindow.postMessage( { 
				origin: 'areoi-platform',
				inspect: this.inspect,
				zoom: this.zoom
			}, this.preview );
		},
		'preview_size': function()
		{
			var $ = window.$;

			$( '.iframe' )[0].contentWindow.postMessage( { 
				origin: 'areoi-platform',
				inspect: this.inspect,
				zoom: this.zoom
			}, this.preview );
		},
		'wp_page': function()
		{
			var $ = window.$;

			$( '.iframe' )[0].contentWindow.postMessage( { 
				origin: 'areoi-platform',
				inspect: this.inspect,
				zoom: this.zoom
			}, this.preview );
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		staging()
		{
			var url = 'https://'

			if ( this.workspace && this.workspace.custom_domain ) {
				url += this.workspace.custom_domain + '/preview/'
			} else if ( this.workspace ) {
				url += this.workspace.domain + '/preview/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			url += '?v=' + this.version

			if ( this.site ) {
				var email = this.auth_user.email

				var key = this.site.key

				url += '&user=' + email + '&key=' + key + '&type=design'
			}

			return url
		},

		iframe_width()
		{
			var width = '100%';

			if ( this.preview_size == 'mobile' ) {
				width = '340px'
			}

			if ( this.preview_size == 'tablet' ) {
				width = '820px'
			}

			return width
		},

		iframe_container_height()
		{
			var height = '100%';

			var scale = this.zoom / 100

			if ( this.zoom < 100 ) {
				height = ( this.scroll_height * scale ) + 'px';
			}

			return height;
		},

		transform()
		{
			var scale = this.zoom / 100  

			return 'transition: all 0.25s ease-in-out; transform: scale(' + scale + '); transform-origin: top center;'
		},

	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		var vm = this;
		var $ = window.$
		$(window).on('message', function(event) {
			var data = event.originalEvent.data;

			if ( data.origin === 'areoi-site' ) {
				vm.scroll_height = data.height
				vm.wp_page = data.page
				
				if ( vm.zoom < 100 ) {
					vm.iframe_height = data.height + 'px';
				} else {
					vm.iframe_height = '100%';
				}		
			}
		});		
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		update_site( e )
		{
			this.$emit('update:modelValue', e)
			this.version++
		},

		/*load_iframe()
		{
			this.iframe_loading = false
		},*/

		show_tour()
		{
			var $ = window.$

			$( '#success' ).modal( 'hide' );

			this.$router.push( { name: 'site.design.prototype', query: {} } );

			this.$tours['prototype-tour'].start()
		},

		hide_success()
		{
			this.$router.push( { name: 'site.design.prototype', query: {} } );

			var $ = window.$

			$( '#success' ).modal( 'hide' );
		},

		handle_next( type, resolve )
		{
			if ( ['previous', 'next'].includes( type ) ) {
				setTimeout( () => {
					resolve();
				}, 100);
			} else {
				resolve();
			}
		},

		update_submitted( e )
		{
			this.wp_submitted = e
		},

		load_iframe()
		{
			this.iframe_loaded = true;
		},

		update_zoom( zoom )
		{
			var new_zoom = this.zoom

			if ( zoom > 0 ) {
				new_zoom += zoom
			} else {
				new_zoom += zoom
			}
			if ( new_zoom > 0 && new_zoom < 101 ) {
				this.zoom = new_zoom
			}
		},
	}
}
</script>

<style>
	iframe {
		transition: all 0.5s ease-in-out;
	}
</style>